.mainContent {
  font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif;
  color: #FFFFFF;
  position: relative;
  width: 100%;
  left: 0%;
}

.ezyLogo {
  margin: 0 auto 25px auto;
  height: 150px;
  width: 150px;
}

.siteText {
  font-size: 22px;
  margin-bottom: 20px;
}

.errorMessage {
  margin-bottom: 25px;
  background-color: rgb(231, 76, 60);
  color: rgb(255, 255, 255);
  text-align: center;
  border-radius: 5px;
  border: 1px solid rgb(150, 40, 27);
  font-size: 20px;
  padding: 10px;
}

.mainForm {
  color: #FFFFFF;
  max-width: 400px;
  margin: 0 auto;
  padding-top:5%;
  font-size: 26px;
}

.siteField {
  padding: 10px;
  font-size: 22px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  width: 230px;
}

.submitButton {
  margin: 25px auto 0;
  background-color: #1396C4;
  font-size: 22px;
  border-radius: 10px;
  width: 100%;
  border: 3px solid #FFFFFF;
  color: #FFFFFF;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(102, 102, 102, 0.5);
  -moz-box-shadow: 0px 5px 5px 0px rgba(102, 102, 102, 0.5);
  box-shadow: 0px 5px 5px 0px rgba(102, 102, 102, 0.5);
  line-height: 50px;
  text-decoration: none;
  text-align: center;
}